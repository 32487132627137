import React, { createContext } from 'react';
import { node, object } from 'prop-types';
import { useImpressionManager } from '@fiverr-private/impressionable';
import ImpressionEventContainer from '../ImpressionEventContainer';

export const EventReportingManagerContext = createContext({
    context: {},
    enabled: true,
});

const WithImpressionManager = ({ children }) => {
    useImpressionManager();
    return <ImpressionEventContainer>{children}</ImpressionEventContainer>;
};
WithImpressionManager.propTypes = {
    children: node.isRequired,
};

const EventReportingManager = ({ context = {}, children }) => {
    const value = { context, enabled: true };

    return (
        <EventReportingManagerContext.Provider value={value}>
            <WithImpressionManager>{children}</WithImpressionManager>
        </EventReportingManagerContext.Provider>
    );
};

EventReportingManager.propTypes = {
    children: node.isRequired,
    context: object,
};

export default EventReportingManager;
