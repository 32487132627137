import { loadable } from '@fiverr-private/perseus';

const DmBriefCreationTextArea = loadable(
    () => import(/* webpackChunkName: "DmBriefCreationTextArea" */ './DmBriefCreationTextArea')
);

export const DM_BRIEF_CREATION_CONFIG = {
    components: [
        {
            component: DmBriefCreationTextArea,
            options: {
                name: 'DM Brief Creation Text Area',
                inputs: [
                    {
                        name: 'source',
                        type: 'text',
                        required: true,
                        defaultValue: 'Builder.io Page',
                    },
                ],
                defaults: {
                    bindings: {
                        'component.options.currencyObject': 'state.contextData.currencyObject',
                    },
                },
            },
        },
    ],
};
